import React from 'react'
import FeatureCards from '../../components/features/FeatureCards'

function OverviewGrid() {

    const featureIndex = [
        {
            title: "Upload",
            para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
            title: "Filter",
            para: "Maecenas nec leo id nulla molestie aliquam at eu lectus.",
        },
        {
            title: "Audit",
            para: "Mauris tellus turpis, varius nec iaculis at, hendrerit ut sapien. ",
        },
    ]

    const training = [
        {
            title: "Over 60+ courses",
            para: "Choose from our library of pre-built safety awareness courses.",
            link: '/features/training/',
        },
        {
            title: "Customize Your Courses",
            para: "Set expiration dates, pass rates, and let workers challenge quizzes + many more options available for each course.",
        },
        {
            title: "Assign by role",
            para: "Add course requirements based on job role. New workers can easily see what is required of them.",
        },
        {
            title: "Optional or Required",
            para: "Decide which courses you want to make required for your workers and which ones you want to make optional.",
        },
        {
            title: "Notifications",
            para: "Your safety team and your workers will be notified when course expirations are coming due.",
        },
        {
            title: "Compliance Monitoring",
            para: "Training dates, expirations, and quiz results are all tracked in Workhub.",
        },
        {
            title: "Get Feedback",
            para: "Get feedback on the courses to gain valuable insight from your workers.",
        },
    ]

    const certificate = [
        {
            title: "Upload",
            para: "Upload digital copies of employees' off-site training and compliance certificates.",
        },
        {
            title: "Assign ",
            para: "Decide which roles in your organization require tickets. Set default expiry terms or make certificates optional.",
        },
        {
            title: "Advise",
            para: "In the event workers need to go elsewhere for their training you can recommend a preferred provider, supply workers with a PO# or issue special instructions.",
        },
        {
            title: "Notifications",
            para: "Workers will be shown and notified of their missing tickets and will be reminded to upload scanned documents or pictures from their phone. Supervisors can also track the status of their subordinates' tickets.",
        },
        {
            title: "Approval",
            para: "Your dashboard will show a count of pending certificate submissions, so it is easy to scroll through, review and approve them.",
        },
        {
            title: "Schedule Training Sessions",
            para: "Coming Due and Past Due reports make it easy to identify workers that need to be recertified.",
        },
        {
            title: "Invite Workers",
            para: "Save money and get everyone certified immediately by setting up a training session. The system will send an email, invite the worker and place a reminder on their dashboard.",
        },

    ]

    const policies = [
        {
            title: "Upload",
            para: "Upload policies from your safety manual and organize them as they appear in your table of contents with section numbers and versions.",
        },
        {
            title: "Version Control",
            para: "Make changes to individual policies and roll them out to workers without resetting the entire manual.",
        },
        {
            title: "Link Forms to Policies",
            para: "Link relevant forms to policies so that workers can access them while reading policies. ",
        },
        {
            title: "Upload Current Policies",
            para: "Policies are sorted as they appear in your table of contents. Deploy all or portions of the manuals to workers, as needed.",
        },
        {
            title: "Policy Acknowledgements",
            para: "Select a pre-set amount of time that workers must spend on each policy before they can acknowledge it and sign off.",
        },
        {
            title: "Track Compliance",
            para: "Track acknowledgement compliance for all your workers and submit reports to management on the status of all new safety policies.",
        },
        {
            title: "Feedback",
            para: "As workers read your policies, they can send feedback directly to your safety team to use as ideas for improvements.",
        },
    ]

    const procedures = [
        {
            title: "Build",
            para: "Use our text editor, upload a PDF, or link to a YouTube video to create Procedures. ",
        },
        {
            title: "Assign",
            para: "Choose which positions or departments in your company need to acknowledge & view Procedures.",
        },
        {
            title: "Contacts",
            para: "Set a contact so that workers can see who to connect with if they have any questions about a given Procedure.",
        },
        {
            title: "Job Hazard Analysis",
            para: "Outline hazard analysis of procedural tasks and assign to risk level, probability of hazard occurring, and severity of outcome.",
        },
        {
            title: "Set a Review Clock",
            para: "Choose a length of time the policy must be reviewed before it can be considered acknowledged.",
        },
        {
            title: "History",
            para: "Download prior versions of procedures for audit purposes and update current versions easily. ",
        },
        {
            title: "Feedback",
            para: "Collect comments from workers, respond and optionally share with workforce.",
        },
        {
            title: "Track Compliance",
            para: "View which versions have been acknowledged by workers, and grant or reset an acknowledgment if necessary. ",
        },

    ]

    const competencies = [
        {
            title: "Assess",
            para: "Ensure that your workers are safely and successfully performing procedures and daily tasks.",
        },
        {
            title: "Prerequisites",
            para: "Link policies, online training & certifications that are necessary to complete prior to a Competency Assessment.",
        },
        {
            title: "Checklist",
            para: "Build a checklist of behaviors, concepts & tasks that must be performed and observed during an operation, for a worker to be considered competent.",
        },
        {
            title: "Qualified Assessors",
            para: "Create a list of people that are qualified to test other members of your workforce.",
        },
        {
            title: "Commenting",
            para: "Assessors can add comments to detail worker performance or show where improvements need to be made. ",
        },
        {
            title: "History and Compliance",
            para: "Track and report on worker competency and follow up with those missing an assessment. ",
        },
        {
            title: "Performance Criteria",
            para: "Designate workers as Requires Supervision, Independently Competent or Instructor/Assessor.",
        },

    ]

    const orientations = [
        {
            title: "Site Orientation",
            para: "Build a site orientation that covers who to report to, procedures for reporting incidents and emergencies, site evacuation, muster points, prohibited areas, and any other site-specific protocols you may have.",
        },
        {
            title: "Hosting",
            para: "If you already have an orientation video, we will host them for free, or we can offer competitive pricing on content creation with our in-house team.",
        },
        {
            title: "Screening",
            para: "Create screening questions to determine whether visitors are allowed onsite. For example: Proper PPE, training and that they meet government health requirements (e.g. Covid-19).",
        },
        {
            title: "Track Completion",
            para: "A record of completed orientations is available in your admin dashboard and is searchable by subcontractor company.",
        },
        {
            title: "Worker ID",
            para: "Look up whether individual workers have completed orientations by checking their WorkerID.",
        },
        {
            title: "Visitor Logs",
            para: "Log visitors and deliveries to a site using a QR code for easy access. ",
        },
        {
            title: "In / Out Board",
            para: "Require workers to check in and out of a site and review a manifest of everyone currently on site. ",
        },
        {
            title: "History Lookups",
            para: "See who accessed a location to assist with incident investigations or to enable contact tracing. ",
        },

    ]



    const SDS = [
        {
          title: "Upload",
          para: "Replace bulky Safety Data Sheet binders by uploading a digital copy from a suppliers’ website. ",
        },
        {
          title: "QR Codes",
          para: "Label your materials with QR codes to allow workers to simply scan for quick SDS lookup. ",
        },
        {
          title: "Notes",
          para: "Add unique notes to SDS to provide further insight for workers. ",
        },
        {
          title: "Safe Handling Instructions",
          para: "Safe handling instructions allow you to print off workplace labels that comply with both WHMIS (Workplace Hazardous Materials Information System) and GHS (Globally Harmonized System) standards. ",
        },
      ]

    const inspections = [
        {
            title: "Customized Checklists",
            para: "Create your checklist of items to inspect and add guidance to help workers complete them all online.",
        },
        {
            title: "Scheduled Intervals",
            para: "Assign a frequency in which inspections of assets or sites must occur. ",
        },
        {
            title: "View Trends",
            para: "See detailed history for each check and track its changes over time or look up the inspection history for an asset or site. ",
        },
        {
            title: "Follow up Actions",
            para: "Approve, comment, or create action items from submitted inspections to track that concerns are being dealt with appropriately.",
        },
        {
            title: "Approval",
            para: "Inspections requiring a review will appear as outstanding in the Administrator dashboard.",
        },

    ]

    const screening = [
        {
            title: "Assess Workers & Visitors",
            para: "Grant/deny physical access based on various criteria such as health checks (COVID-19), PPE and compliance data (certificates, orientations, and competencies).",
        },
        {
            title: "Build",
            para: "Create custom checklists that are unique to your location.",
        },
        {
            title: "Import / Copy",
            para: "Import questions from templates or copy questions from a previous location.",
        },
        {
            title: "Log",
            para: "All details of the visit are logged for full audit and tracing needs.",
        },
        {
            title: "Worker Manifest",
            para: "Look up who is currently on site in the event of an emergency.",
        },
        {
            title: "In-Out Board",
            para: "Workers sign out when leaving the site providing in-out data for reporting.",
        },

    ]

    const incidents = [
        {
            title: "Reporting",
            para: "Log incidents in the workplace to collect statistics on the frequency and severity of incidents and near misses.",
        },
        {
            title: "Description",
            para: "Create a detailed report by uploading a description of the events, date, time, location, photos, linking assets and the environment or people involved.",
        },
        {
            title: "Injuries / Revised Work",
            para: "Reporters can log injuries to body parts on a human diagram and track an employee’s lost time and restricted work.",
        },
        {
            title: "Assign and Investigator",
            para: "Assign an investigator who can add root causes or attach additional information like witness statements, doctors’ reports, OSHA correspondence and more.",
        },
        {
            title: "Add Action Items",
            para: "Eliminate or mitigate each incident’s contributing factors by adding action items to any incident. This will ensure that corrective actions are carried out and signed off on.",
        },
        {
            title: "Charting",
            para: "Charts and graphs in the Admin panel show companywide trends in incident frequency, time lost, associated costs and more.",
        },

    ]

    const bbso = [
        {
            title: "Behavior-Based Safety Observation",
            para: "Observe employees in their day-to-day activities to ensure that they are exhibiting behaviors and attitudes that contribute to a safe workplace.",
        },
        {
            title: "Identify ",
            para: "Identify behaviors that contribute to unwanted outcomes at your workplace and build checklists to sign-off on.",
        },
        {
            title: "Assign",
            para: "Assign observations by position and location.",
        },
        {
            title: "Designate",
            para: "Choose staff or supervisors that are qualified to assess other employees.",
        },
        {
            title: "Analyze the Results",
            para: "Filter your submissions by date and track the results over time. ",
        },

    ]

    const actionItems = [
        {
            title: "Assign",
            para: "Identify deficiencies and corrective actions and assign them to workers. ",
        },
        {
            title: "Notify",
            para: "The system notifies workers that they have been assigned an Action Item via SMS or email.",
        },
        {
            title: "Corrective Actions",
            para: "Workers attach photos, add comments and more, to ultimately complete Action Items.",
        },
        {
            title: "Verify",
            para: "Administrators verify that Action items are completed properly and sign-off once they are complete. ",
        },

    ]

    const safetyMeetings = [
        {
            title: "Paper trail",
            para: "Create a digital paper trail of what is discussed at safety meetings and who was in attendance.",
        },
        {
            title: "Meeting Types",
            para: "Develop meeting types to serve as templates for meeting creation. Choose whether they are not applicable, optional, or mandatory.",
        },

        {
            title: "Supporting Documents",
            para: "Link any relevant procedures, inspections, hazards, and action items to help support the discussion.",
        },

        {
            title: "Set up",
            para: "Workers create meetings from meeting types and select dates, times, attendees, and can leave preliminary notes.",
        },
        {
            title: "Review",
            para: "An administrator can review the meeting (if specified) and approve. ",
        },
        {
            title: "Missed Meetings",
            para: "Missed meetings show upon the worker dashboard and meeting minutes will show up in the “Required” tab.",
        },
    ]

    const forms = [
        {
            title: "Templating",
            para: "Upload a template for each form type, or link to an online form and specific submission frequencies.",
        },
        {
            title: "Identify & Assign",
            para: "If a submission reveals that work is needed, an admin can assign an action item. The form it was created in is automatically linked providing context for workers. ",
        },
        {
            title: "Notifications",
            para: "Workers who have been assigned tasks receive notifications via SMS (Text) or Email. ",
        },
        {
            title: "Submit Virtually",
            para: "Workers can submit forms by email, fax, web or on their smartphone. Click on the form or web link to fill it out and submit.",
        },

    ]

    const bulletins = [
        {
            title: "Notify",
            para: "Ensure workers are reading important correspondence by sending out bulletins and alerts though email and SMS. ",
        },
        {
            title: "Recipient Filtering",
            para: "Select the recipients by position and location to ensure the bulletins and alerts go to relevant workers. ",
        },
        {
            title: "Set a Review Clock",
            para: "Choose a length of time the bulletins and alerts must be reviewed for before they can be considered acknowledged.",
        },
        {
            title: "Read Receipts",
            para: "Read receipts allow admins to see who has reviewed the bulletins and who has not.",
        },
        {
            title: "Send Reminders",
            para: "Send a reminder to all workers that have not reviewed the bulletin to prompt more readership.",
        },
        {
            title: "Commenting",
            para: "Workers can comment on bulletins and admins can approve the comment to make it public to all workers.",
        },
    ]

    const suggestions = [
        {
            title: "Engagement",
            para: "Drive up engagement in your organization by allowing employees to submit suggestions regarding workplace culture, policies/procedures, and more.",
        },
        {
            title: "Rate and Comment",
            para: "Get additional feedback by allowing other workers to rate a fellow employee’s suggestion.  ",
        },
        {
            title: "Optional Anonymity",
            para: "Give workers the ability to provide insight without fear of backlash, or criticism. Select whether workers can remain anonymous when suggesting, rating, or commenting. ",
        },
        {
            title: "Approval",
            para: "Select whether admins need to review suggestions and comments before making them visible to other workers. Admins can respond directly to suggestions. ",
        },
        {
            title: "Assess Proposition Value",
            para: "If workers enter an estimated value of benefits and costs saved the software will automatically calculate a return on investment. ",
        },
        {
            title: "Transparency",
            para: "Workers can see which suggestions have been implemented and approved, and which are pending or declined.",
        },
    ]


    const polls = [
        {
            title: "Gain Insights",
            para: "Poll your workforce on company issues like, “What make should the new fleet vehicles be?” or more light-hearted topics such as, “Where should the company Christmas party be?”",
        },
        {
            title: "Elevate Compliance",
            para: "Workers will want to weigh in on the more fun, extracurricular themed polls. Setting a minimum compliance level will encourage your workers to get up to date so they can participate.",
        },
        {
            title: "Share Results",
            para: "Choose to automatically send out results in a Bulletin when a poll ends or generate a bulletin draft on demand for completed polls. This includes a summary and graph of results.",
        },
        {
            title: "Track Responses and Participation",
            para: "Refer to the participation chart to review overall interaction with the poll. View and filter individual responses by position and location in the Worker Responses tab.",
        },

    ]


    const surveys = [
        {
            title: "Create a Survey",
            para: "Start from one of our templates or create your own to gather feedback from workers. Post statements for workers to rate on a scale of 1-5 stars. ",
        },
        {
            title: "Track Responses",
            para: "Your team can review the rating for individual statements, or the full survey. ",
        },
        {
            title: "Frequency & Trends",
            para: "Select whether a Survey is required Quarterly, Semi-Annually, or Annually and start tracking trends. ",
        },
        {
            title: "Make Improvements",
            para: "Use the data gathered in the surveys to help your team come up with an action plan for improvements. ",
        },
        {
            title: "Answer Honestly",
            para: "Allow workers to submit surveys anonymously to gather quality feedback ratings and comments. ",
        },
    ]

    const workers = [
        {
            title: "Organizational Structure",
            para: "Create a hierarchy of workers and supervisors so that employees know who to report to, and supervisors have a clear picture of who they need to support and manage. ",
        },
        {
            title: "Supervisory Tools",
            para: "We provide a clean interface that makes it easy for Supervisors to view their subordinate’s ongoing tasks and compliance. ",
        },
        {
            title: "Filter by Credentials",
            para: "Admins can filter workers by credentials to determine who is qualified to work on certain jobsites. ",
        },
        {
            title: "Full Dashboard View",
            para: "Click on individual workers to gain a full view of their dashboard, compliance, and outstanding items. ",
        },
        {
            title: "Charts and Trends",
            para: "Our software allows you to monitor company-wide compliance trends based on data from each worker. ",
        },
        {
            title: "Greenhand",
            para: "Designate new and inexperienced workers as ‘Greenhands’ so that they can receive the supervision and mentorship they need to succeed. ",
        },
    ]

    const whistleblower = [
        {
            title: "Promote Speak-up Culture",
            para: "Allow workers to submit policy breaches or wrongdoings within the organization. Breach reports can be linked to a policy to allow insight on which policies have been breached over time.  ",
        },
        {
            title: "Full Privacy & Anonymity",
            para: "Workers can report a breach with full anonymity. All records are fully encrypted in the database – even our developers can’t peek at them. ",
        },
        {
            title: "Assigned Recipients",
            para: "Select the members of your organization to be possible recipients of the Whistleblower reports. Workers can choose from this list when reporting.  ",
        },
        {
            title: "Notifications",
            para: "Recipients are notified via SMS and/or Email that a new breach report has been submitted.",
        },
        {
            title: "Detailed Submission Management",
            para: "Recipients can follow-up on the report with commenting, document upload, detailed log, and status changes. Recipients are the only ones who can view the details. ",
        },
        {
            title: "Reference Number",
            para: "Workers are provided a reference number upon submission. This number can be used for future submissions if the worker has additional comments or repeated occurrences to report. ",
        },
    ]

    const rewards = [
        {
            title: "Optional",
            para: "Rewards is an optional but highly recommended part of our Recognition program intended to incentivize workers, inspire interest, and develop a sense of pride and credibility within and outside your organization. ",
        },
        {
            title: "Onboarding Boost",
            para: "A large portion of Recognition points will be earned during the onboarding process which can be immediately redeemed for a shirt and hat to welcome new employees to the team. ",
        },
        {
            title: "Huge Selection",
            para: "We offer a MASSIVE selection of high-quality apparel with sizes ranging from XS-3XL. The clothing comes in a handful of flattering shades that are designed to make your logo pop. ",
        },
        {
            title: "Don’t Purchase in Bulk",
            para: "Your company does not have to purchase sizes/styles by the dozen. Workers looking to redeem points can choose items in our Recognition catalog and only those selected items will be sent. ",
        },
        {
            title: "Convenient Shipping",
            para: "Selected apparel will be shipped directly from our embroidering/shipping department to your workers home in a care package with 4 additional bonus items. ",
        },
    ]
    const anniversaries = [
        {
            title: "Setup Your Program",
            para: "Choose a budget and which types of items are available for each year."
        },
        {
            title: "Let Your Company Culture Grow",
            para: "Workers can choose their item when their anniversary date approaches. Once processed, it gets delivered directly to their door."
        },
        {
            title: "See the Results",
            para: "Visibly see your tenure as workers wear their items from each year. Can help decrease company turnover, and increase satisfaction."
        },
    ]
    const kudos = [
        {
            title: "Setup Your Program",
            para: "Enter your organization’s core values, choose how frequently workers can send kudos, and how many points are earned per submission."
        },
        {
            title: "Let Your Company Culture Grow",
            para: "Workers can submit stories of peers embodying your organizations core values, allowing your organization to celebrate excellence."
        },
        {
            title: "See the Results",
            para: "Visibly see your company culture grow as your Kudo Board fills with submissions from your workforce."
        },
    ]

    const scratchcards = [
        {
            title: "Incentivize",
            para: "Hand out a Scratch Card whenever an employee does something exemplary such as: helping new employees, following procedures to a tee, or treating a customer exceedingly well.",
        },
        {
            title: "Customize",
            para: "Scratch Cards can be customized with your company branding / logo.",
        },
        {
            title: "Value Sets",
            para: "We offer 3 different point value sets (standard, high and precious) which award a higher or lower range of points depending on what works best for your budget.",
        },
        {
            title: "Redemption",
            para: "Scratch cards can be built into an existing rewards inventory or companies can purchase points for Workhub’s rewards system.",
        },
    ]
    const joke = [
        {
            title: "Setup Your Program",
            para: "Choose how many compliance items and what compliance percentage a worker needs to view the joke to match your organization’s current needs."
        },
        {
            title: "Let Your Company Culture Grow",
            para: "Once workers have met the requirements, they can view the joke of the week and choose a reaction to the joke."
        },
        {
            title: "See the Results",
            para: "See your compliance percent increase as workers complete more onboarding items."
        },
    ]

    const legislation = [
        {
          title: "Choose from our library",
          para: "Our library contains legislation links from all around the world. Filter by continent, country, and state/province. ",
        },
        {
          title: "Easy to enable",
          para: "Enable any legislation related to your organization with a couple of clicks. ",
        },
        {
          title: "Report broken links ",
          para: "Easily report any broken links. If you require legislation that isn’t listed, send us a message & our team will add it in for you!",
        },
      ]

      const audits = [
        {
          title: "Self-Audit ",
          para: "Ensure your ready for your next Audit by utilizing our standard specific preparation checklists.",
        },
        {
          title: "Choose from our Library",
          para: "We have over 20 different audit standards pre-loaded into our system to choose from.",
        },
        {
          title: "Request an Audit Standard",
          para: "If we are missing a standard that you need, simply contact us!",
        },
        {
          title: "Invite your Auditor",
          para: "Your auditor can login and see your safety program without having to set foot in the workplace.",
        },
        {
          title: "Integrated with our System",
          para: "Attach documents, or link elements to other Workhub components to show your companny's due diligence.",
        },
        {
          title: "Self-Score",
          para: "Score yourself, designate a status (Compliant, Minor Deficiency or Major Deficiency) and leave comments.",
        },
      ]


    return (
        <div>
            <h2>Online Training</h2>

            <div className="feature-overview-grid change-spacing">
                {training.map(item => (
                    <FeatureCards title={item.title} para={item.para} link={item.link}/>
                ))}
            </div>

            <h2 id="certificate">Certificate Tracking </h2>

            <div className="feature-overview-grid">
                {certificate.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="policy">Policies</h2>

            <div className="feature-overview-grid">
                {policies.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="procedure">Operating Procedures </h2>

            <div className="feature-overview-grid">
                {procedures.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="competency">Competency Assessments </h2>

            <div className="feature-overview-grid">
                {competencies.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="contractor">Contractor Orientations </h2>

            <div className="feature-overview-grid" >
                {orientations.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="screening">Workplace Screening </h2>

            <div className="feature-overview-grid">
                {screening.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="inspection">Inspections</h2>

            <div className="feature-overview-grid">
                {inspections.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2  id="incident">Incident Reporting </h2>

            <div className="feature-overview-grid">
                {incidents.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="bbso">Behavior Observations </h2>

            <div className="feature-overview-grid">
                {bbso.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="action">Action Items</h2>

            <div className="feature-overview-grid">
                {actionItems.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="safety">Safety Meetings </h2>

            <div className="feature-overview-grid">
                {safetyMeetings.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="forms">Forms</h2>

            <div className="feature-overview-grid">
                {forms.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="bulletin">Bulletins and Alerts </h2>

            <div className="feature-overview-grid">
                {bulletins.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="suggestions">Suggestions</h2>

            <div className="feature-overview-grid">
                {suggestions.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="poll">Polls</h2>

            <div className="feature-overview-grid">
                {polls.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="survey">Surveys</h2>

            <div className="feature-overview-grid">
                {surveys.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="worker">Worker Management</h2>

            <div className="feature-overview-grid">
                {workers.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="whistle">Whistleblower</h2>

            <div className="feature-overview-grid">
                {whistleblower.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="rewards">Rewards</h2>

            <div className="feature-overview-grid">
                {rewards.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>
{/* 
            <h2 id="anniversaries">Anniversaries</h2>

            <div className="feature-overview-grid">
                {anniversaries.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div> */}

            {/* <h2 id="kudos">Kudos & Core Values</h2>

            <div className="feature-overview-grid">
                {kudos.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div> */}

            <h2 id="scratch">Scratch Cards </h2>

            <div id="end-trigger-md">
            <div className="feature-overview-grid" id="sds">
                {scratchcards.map(item => (
                    <FeatureCards title={item.title} para={item.para}/>
                ))}
            </div>
            </div>

            {/* <h2 id="joke">Joke of The Week </h2>

            <div id="end-trigger-md">
            <div className="feature-overview-grid" id="sds">
                {joke.map(item => (
                    <FeatureCards title={item.title} para={item.para}/>
                ))}
            </div>
            </div> */}

            <h2 id="end-trigger-lg">SDS</h2>

            <div className="feature-overview-grid" >
    
                {SDS.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="legislation">Legislation Hosting</h2>

            <div className="feature-overview-grid">
                {legislation.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

            <h2 id="audit-prep">Audit Preparation</h2>

            <div className="feature-overview-grid">
                {audits.map(item => (
                    <FeatureCards title={item.title} para={item.para} />
                ))}
            </div>

        </div>
    )
}

export default OverviewGrid
