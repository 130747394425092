import React, { useRef, useEffect, useState } from 'react'
import Layout from '../../components/layouts'
// import FeatureCards from '../../components/features/FeatureCards'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import OverviewGrid from '../../components/features/OverviewGrid'
import { Link, animateScroll as scroll } from "react-scroll";
import { Link as GatsbyLink } from 'gatsby'
import SEO from '../../components/common/SEO'

gsap.registerPlugin(ScrollTrigger)

function Overview() {

  const [open, setOpen] = useState(false);

  const navBar = useRef(null)

  useEffect(() => {

    ScrollTrigger.matchMedia({

      "(max-width: 1169px)": function () {
        ScrollTrigger.create({
          trigger: '.feature-index-nav',
          endTrigger: '#step-4',
          start: 'center center',
          endTrigger: '#end-trigger-md',
          pin: true,
          pinSpacing: false,
          // markers: true
        });
      }
    });

    ScrollTrigger.matchMedia({

      "(min-width: 1170px)": function () {
        ScrollTrigger.create({
          trigger: '.feature-index-nav',
          endTrigger: '#step-4',
          start: 'center center',
          endTrigger: '#end-trigger-lg',
          pin: true,
          pinSpacing: false,
          // markers: true
        });
      }
    });

    // Dont remove this code - this is for a sticky nav

    window.onscroll = function () { grabTheNav() };

    // Get the navbar
    var navbar = navBar.current

    // Get the offset position of the navbar
    var sticky = navbar.offsetTop

    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    const grabTheNav = () => {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky")
      } else {
        navbar.classList.remove("sticky");
      }
    }


  }, [])

  const clickSticky = () => {
    setOpen(true)

    // console.log(open)
  }

  const closeNav = () => {
    setOpen(false)

    // console.log(open)
  }

  const navItems = () => {
    return (
      <>
        <ul>
          <li>
            <Link
              activeClass="active"
              to="online"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >Online Training</Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="certificate"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Certificate Tracking</Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="policy"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Policies</Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="procedure"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Operating Procedures</Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="competency"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Competency Assessments</Link>

          </li>
          <li>
            <Link
              activeClass="active"
              to="contractor"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Contractor Orientations</Link>

          </li>
          <li>
            <Link
              activeClass="active"
              to="screening"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Workplace Screening</Link>

          </li>
          <li>
            <Link
              activeClass="active"
              to="inspection"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Inspections</Link>

          </li>
          <li>
            <Link
              activeClass="active"
              to="incident"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Incident Reporting</Link>

          </li>
          <li>
            <Link
              activeClass="active"
              to="bbso"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Behavior Observations</Link>

          </li>
          <li>
            <Link
              activeClass="active"
              to="action"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Action Items</Link>

          </li>
          <li>
            <Link
              activeClass="active"
              to="safety"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Safety Meetings</Link>

          </li>
          <li>
            <Link
              activeClass="active"
              to="forms"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Forms</Link>

          </li>
          <li>
            <Link
              activeClass="active"
              to="bulletin"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Bulletins and Alerts</Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="suggestions"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Suggestions</Link>

          </li>
          <li>
            <Link
              activeClass="active"
              to="poll"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Polls</Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="survey"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Perception Surveys</Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="worker"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Worker Management</Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="whistle"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Whistleblower</Link>
          </li>
            <li>
              <Link
                activeClass="active"
                to="rewards"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
              >Rewards</Link>
            </li>
            <li>
              <a
                activeClass="active"
                href="/features/anniversaries/"
                target="_blank"
                // spy={true}
                // smooth={true}
                // offset={-150}
                // duration={500}
              >Anniversaries</a>
            </li>
            <li>
            <a
                activeClass="active"
                href="/features/kudos/"
                target="_blank"
                // spy={true}
                // smooth={true}
                // offset={-150}
                // duration={500}
              >Kudos & Core Values</a>
            </li>
            <li>
            <Link
              activeClass="active"
              to="scratch"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Scratch Cards</Link>
            </li>
            <li>
            <a
                activeClass="active"
                href="/features/joke-of-the-week/"
                target="_blank"
                // spy={true}
                // smooth={true}
                // offset={-150}
                // duration={500}
              >Joke of The Week</a>
            </li>
          <li>
            <Link
              activeClass="active"
              to="sds"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >SDS</Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="legislation"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Legislation Hosting</Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to="audit-prep"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >Audit Preparation</Link>
          </li>
        </ul>
      </>
    )
  }

  const seo = {
    title: "Workhub | Features Overview | Free EHS Management Software",
    description: "An overview of the functionality, tools & key components of our robust in-app Features.",
    image: "/overview/social-image.png",
    // pathname,
    // article
  }

  return (
    <Layout>
       <SEO {...seo}/>
      <header className='feature-index-header' id='online'>



        <div className="over-icon-container">

          <img src='/overview/banner8.png' style={{ margin: 'auto' }} alt="Feature banner with icons" />

        </div>


        <div className='index-header-container'>
          <h1>Workhub Features</h1>
          <p >An overview of the functionality and key components of our robust in-app Features.</p>
          <p style={{ marginBottom: '0px' }}><a class="slider_btn btn_hover feature-index-button" href="/features/">Go to Features</a></p></div>
      </header>

      <div className="overview-sticky-nav" ref={navBar}><p onClick={clickSticky}>OVERVIEW MENU</p></div>

      <nav className={`overview-hidden-nav ${open ? "open_nav" : ""}`} onClick={closeNav}>
        {/* <p>Close</p> */}
        {navItems()}
      </nav>

      <main className='feature-index-main' >

        <nav className='feature-index-nav'>
          <h5>Components:</h5>
          {navItems()}
        </nav>


        <div className='feature-index-container'>
          <OverviewGrid />
        </div>


      </main>
    </Layout>
  )
}

export default Overview


{/* <div style={{ position: 'relative', width: '100%', height: '35vh' }}>

            <img 
            className='overview-icon-img' 
            src="/blue-icons/orientations.svg"
            style={{ position: 'absolute', top: '0px', left: '0px', height: '200px', width: '200px' }} />

            <img
              className='overview-icon-img'
              src="/blue-icons/action.svg"
              style={{ position: 'absolute', top: '10px', left: '700px', height: '250px', width: '250px' }} />


            <img 
            className='overview-icon-img' 
            src="/blue-icons/assets.svg"
            style={{ position: 'absolute', top: '0px', left: '400px', height: '100px', width: '100px' }} />

            <img
              className='overview-icon-img'
              src="/blue-icons/workers.svg"
              style={{ position: 'absolute', bottom: '60px', left: '20px', height: '150px', width: '150px' }} />

            <img 
            className='overview-icon-img' 
            src="/blue-icons/behaviour.svg"
            style={{ position: 'absolute', bottom: '-20px', left: '1900px', height: '300px', width: '300px' }} />

            <img
              className='overview-icon-img'
              src="/blue-icons/bulletins.svg"
              style={{ position: 'absolute', top:'-10px', left: '270px', height: '350px', width: '350px' }} />

            <img 
            className='overview-icon-img' 
            src="/blue-icons/certificates.svg"
            style={{ position: 'absolute', bottom: '-300px', left: '200px', height: '400px', width: '400px' }} />

            <img 
            className='overview-icon-img' 
            src="/blue-icons/competencies.svg"
            style={{ position: 'absolute', top: '-20px', left: '1750px', height: '150px', width: '150px' }} />

            <img 
            className='overview-icon-img' 
            src="/blue-icons/credentials.svg"
            style={{ position: 'absolute', top: '0px', left: '2250px', height: '300px', width: '300px' }} />


            <img 
            className='overview-icon-img' 
            src="/blue-icons/forms.svg"  
            style={{ position: 'absolute', top: '0px', left: '2000px', height: '150px', width: '150px' }} />

            <img
              className='overview-icon-img'
              src="/blue-icons/joke.svg"
              style={{ position: 'absolute', top: '0px', left: '1100px', height: '500px', width: '500px' }} />

            <img
              className='overview-icon-img'
              src="/blue-icons/rewards.svg"
              style={{ position: 'absolute', top: '160px', left: '1700px', height: '150px', width: '150px' }} />

            <img
              className='overview-icon-img'
              src="/blue-icons/subs.svg"
              style={{ position: 'absolute', bottom: '20px', left: '850px', height: '150px', width: '150px' }} />

<img
              className='overview-icon-img'
              src="/blue-icons/procedures.svg"
              style={{ position: 'absolute', bottom: '-80px', left: '1630px', height: '200px', width: '200px' }} />

<img
              className='overview-icon-img'
              src="/blue-icons/inspections.svg"
              style={{ position: 'absolute', bottom: '-20px', left: '2300px', height: '150px', width: '150px' }} />

<img
              className='overview-icon-img'
              src="/blue-icons/audit.svg"
              style={{ position: 'absolute', bottom: '0px', left: '600px', height: '150px', width: '150px' }} />

          </div> */}
